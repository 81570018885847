// src/js/content_safety.js
import ContentSafetyClient from "@azure-rest/ai-content-safety";
import { isUnexpected } from '@azure-rest/ai-content-safety';
import { AzureKeyCredential } from '@azure/core-auth';

export class ContentSafety {
    constructor(endpoint, apiKey) {
        const credential = new AzureKeyCredential( apiKey );
        this.client = new ContentSafetyClient(endpoint, credential);
    }

    async analyzeText(text) {

        const analyzeOption = { text };
        const analyzeParam = { body: analyzeOption };

        try {
            const result = await this.client.path('/text:analyze').post( analyzeParam );

            if ( isUnexpected( result ) ) {
                throw result;
            }

            return result.body.categoriesAnalysis;
        } catch (error) {
            console.error('Error analyzing content:', error);
            throw error;
        }
    }

    isSafe(analysis) {
        // Implement your safety criteria here
        return analysis.every(category => category.severity < 2);
    }

    displayAnalysis( analysis ) {
        const status = this.isSafe( analysis ) ? 'Safe' : 'Unsafe';
        let text = `<h4>Status: ${status}</h4>`;
        analysis.forEach( cat => {
            text += `${cat.category}: ${cat.severity}<br />`;
        });

        return text;
    }
}