// src/js/speech_recognition.js
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

export class SpeechRecognition {
    constructor(subscriptionKey, region) {
        this.subscriptionKey = subscriptionKey;
        this.region = region;
    }

    async recognizeFromAudioFile(audioFile) {
        const speechConfig = sdk.SpeechConfig.fromSubscription(this.subscriptionKey, this.region);
        const audioConfig = sdk.AudioConfig.fromWavFileInput(audioFile);
        const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
        return this.recognize2( recognizer );
    }

    async recognize( recognizer ) {
        return new Promise((resolve, reject) => {

            recognizer.recognizeOnceAsync(
                result => {
                    switch ( result.reason ) {
                        case sdk.ResultReason.RecognizedSpeech:
                            resolve(result.text);
                            break;

                        case sdk.ResultReason.NoMatch:
                            resolve( false );
                            break;

                        default:
                            console.log( 'Recognizer error: ', result );
                            reject(new Error('Speech recognition failed' ));
                    }
                },
                error => {
                    console.log( error );
                    reject(error);
                }
            );
        });
    }

    async recognize2( recognizer ) {
        return new Promise((resolve, reject) => {
            let text = '';

            recognizer.sessionStopped = ( r, e ) => {
                console.log( 'session stopped', r, e );
                resolve( text );
            };

            recognizer.recognized = ( r, e ) => {
                text += e.result.text + ' ';
            };

            recognizer.startContinuousRecognitionAsync( null, e => reject(e) );
        });

    }
}