export class VolumeMeter {

	analyzer;
	source;
	stream;
	canvas;
	context;

	constructor( stream, element ) {
		this.stream = stream;
		this.canvas = element;
		this.audioContext = new AudioContext();
		this.analyzer = this.audioContext.createAnalyser();
		this.analyzer.fftSize = 32;
		this.source = this.audioContext.createMediaStreamSource( stream );
		this.source.connect( this.analyzer );

		this.context = element.getContext('2d');

		this.run();
	}

	run() {
		const val = this.getVolume();
		const ctx = this.context;
		const grad = ctx.createLinearGradient( 0, 0, this.canvas.width, 0 );
		grad.addColorStop( 0, '#4caf50' );
		grad.addColorStop( .5, '#4caf50' );
		grad.addColorStop( .8, '#fff138' );
		grad.addColorStop( 1, '#ff0000' );
		
		ctx.clearRect( 0, 0, this.canvas.width, this.canvas.height );
		//ctx.fillStyle = '#4caf50';
		ctx.fillStyle = grad;
		ctx.fillRect( 0, 0, ( val / 100 ) * this.canvas.width, this.canvas.height );

		requestAnimationFrame( this.run.bind( this ) );
	}

	getVolume() {
		const arr = new Uint8Array( this.analyzer.frequencyBinCount );
		this.analyzer.getByteFrequencyData( arr );
		return this.getAverage( arr );
	}

	getAverage( data ) {
		let values = 0;
		const length = data.length;
		for ( let i = 0; i<length; i++ ) {
			values += data[i];
		}

		return values / length;
	}


}