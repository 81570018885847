
export class SkylarAPI {

	get_token() {

	}

	async user_conversation( text, history ) {

		history = history || [];
		history.push({
			role: 'user',
			content: text
		});

		const response = await fetch('/api/user-conversation', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify( history )
		});

		return response.json();
	}


}
