// src/js/audio_capture.js

// @todo: Webm?
import { getWaveBlob } from 'webm-to-wav-converter';

export class AudioCapture {

    mimeType = 'audio/webm';

    constructor() {
        this.stream = null;
        this.mediaRecorder = null;
        this.audioChunks = [];
    }

    async media_stream() {
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (error) {
            console.log( 'Problem gaining access to mic.' );
        }

    }

    async start() {

        this.audioChunks = [];

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: this.mimeType } );
            this.mediaRecorder.ondataavailable = (event) => {
                event.data.size && this.audioChunks.push(event.data);
            };

            this.mediaRecorder.start();
        } catch (error) {
            console.error('Error starting audio capture:', error);
            throw error;
        }
    }

    stop( stopCallback ) {
        if (this.mediaRecorder) {
            if ( stopCallback ) {
                this.mediaRecorder.onstop = async () => {
                    const audioBlob = await this.getAudioBlob();
                    stopCallback( audioBlob );
                };

                /*
                this.mediaRecorder.onstop = () => {
                    const blob = new Blob( this.audioChunks, { type: 'audio/wav' } );
                    stopCallback( blob );
                }
                */
            }
            this.mediaRecorder.stop();
            this.stream.getTracks().forEach(track => track.stop());
        }
    }

    async getAudioBlob() {
        const blob = new Blob( this.audioChunks, { type: this.mimeType } );
        return await getWaveBlob( blob );
    }
}