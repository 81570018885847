// src/js/main.js
import { AudioCapture } from './AudioCapture';
import { SpeechRecognition } from './SpeechRecognition';
import { ContentSafety } from './ContentSafety';
import { SkylarAPI } from './SkylarAPI';
import { VolumeMeter } from './Control/VolumeMeter';
import { TextToSpeech } from './TextToSpeech';

import '../css/styles.css';

class App {
    constructor() {
        this.audioCapture = new AudioCapture();
        this.speechRecognition = new SpeechRecognition(process.env.AZURE_SPEECH_KEY, process.env.AZURE_SPEECH_REGION);
        this.contentSafety = new ContentSafety(process.env.AZURE_CONTENT_SAFETY_ENDPOINT, process.env.AZURE_CONTENT_SAFETY_KEY);
        this.textToSpeech = new TextToSpeech(process.env.ELEVENLABS_API_KEY, process.env.ELEVENLABS_VOICE_ID );
        this.api = new SkylarAPI();


        this.audioBlob = null;
        this.chatgptResponseAudio = null;

        this.chatHistory = [];

        this.setupElements();
    }

    setupElements() {

        const elements = {
            initMicButton: 'init_mic',
            volumeMeter: 'volume_meter',
            capturePlayer: 'capture_player',
            responsePlayer: 'response_player',
            recognizedTextElement: 'recognizedText',
            chatgptResponseElement: 'chatgptResponse',
            contentSafetyStatus: 'contentSafetyStatus',
            statusElement: 'status',
            chatHistoryElement: 'chat-history',
            restartButton: 'restart_session'
        };

        for ( const prop in elements ) {
            this[prop] = document.getElementById( elements[prop] );
        }

        this.bindEventListeners();

        // Start mic if possible. Some browsers (Chrome) require user action to request media. Others do not.
        // Once approved, refreshing the page should be seamless.

        this.startMic()
            .catch( error => console.log( error ) );

    }

    bindEventListeners() {
        this.initMicButton.addEventListener( 'click', () => this.startMic() );
        this.restartButton.addEventListener( 'click', () => this.restartSession() );
        document.addEventListener( 'keydown', e => {
            if ( e.code == 'Space' ) {
                e.preventDefault();
                if ( e.repeat ) { return; }
                this.startListening();
            }
        });
        document.addEventListener( 'keyup', e => {
            if ( e.code == 'Space' ) {
                e.preventDefault();
                this.stopListening();
            }
        });
    }

    async startMic() {
        await this.audioCapture.media_stream();
        this.initMicButton.style.display = this.audioCapture.stream ? 'none' : '';
    }

    async startListening() {
        try {
            this.updateStatus('Listening...');
            await this.audioCapture.start();
            new VolumeMeter( this.audioCapture.stream, this.volumeMeter );
        } catch (error) {
            this.updateStatus('Error starting audio capture');
            console.error(error);
        }
    }

    async stopListening() {
        try {
            this.audioCapture.stop( async (audioBlob) => {
                this.capturePlayer.src = URL.createObjectURL( audioBlob );
                await this.recognizeAudio( audioBlob );
            });

            this.updateStatus('Processing...');
        } catch (error) {
            this.updateStatus('Error processing audio');
            console.error(error);

        }
  }

    async recognizeAudio( audioBlob ) {
        this.displayRecognizedText();
        this.updateStatus( 'Sending audio' );
        try {
            const file = new File( [audioBlob], 'input.wav' );
            //const file = URL.createObjectURL( audioBlob );
            const recognizedText = await this.speechRecognition.recognizeFromAudioFile( file );
            if ( recognizedText ) {
                this.displayRecognizedText(recognizedText);
                this.updateStatus( 'Sending recognized text.' );
                this.sendTextToAgent(recognizedText);
            } else {
                this.displayRecognizedText( 'No speech detected' );
                this.updateStatus('No speech detected');
            }
        } catch( error ) {
            this.updateStatus( 'Error recognizing audio' );
            console.error( error );
        }

    }

    async sendTextToAgent( recognizedText ) {
        try {
            const safetyAnalysis = await this.contentSafety.analyzeText(recognizedText);
            this.contentSafetyStatus.innerHTML = this.contentSafety.displayAnalysis( safetyAnalysis );
            if (this.contentSafety.isSafe(safetyAnalysis)) {
                this.updateStatus( 'Sending to LLM' );
                const chatgptResponse = await this.api.user_conversation( recognizedText, this.chatHistory );
                this.displayChatGPTResponse(chatgptResponse);
                this.updateStatus( 'Generating response audio' );
                await this.synthesizeSpeech( chatgptResponse );
                this.updateStatus( 'Playing response.' );

                this.addChatHistory( recognizedText, chatgptResponse );
            } else {
                this.updateStatus('Content flagged as unsafe');
            }
        } catch (error) {
            this.updateStatus('Error processing audio');
            console.error(error);
        }
    }

    async synthesizeSpeech( text ) {
        this.chatgptResponseAudio = await this.textToSpeech.synthesizeSpeech(text);
        this.playResponse();
        this.updateStatus('');
    }

    playResponse() {
        if (this.chatgptResponseAudio) {
            const url = URL.createObjectURL(this.chatgptResponseAudio);
            this.responsePlayer.src = url;
            const audio = new Audio( url );
            audio.play();
        }
    }

    updateStatus(message) {
        this.statusElement.textContent = message;
    }

    displayRecognizedText(text) {
        this.recognizedTextElement.textContent = text ? text : '';
    }

    displayChatGPTResponse(response) {
        this.chatgptResponseElement.textContent = response;
    }

    addChatHistory( userText, agentText ) {
        const userMessage = {
            role: 'user',
            content: userText
        };

        const agentMessage = {
            role: 'assistant',
            content: agentText
        };

        this.chatHistory.push( userMessage );
        this.displayChatHistory( userMessage );

        this.chatHistory.push( agentMessage );
        this.displayChatHistory( agentMessage );
    }

    displayChatHistory( message, noscroll ) {
        const row = document.createElement( 'div' );
        row.className = 'msg-row msgrole-' + message.role;

        const item = document.createElement( 'div' );
        item.className = 'msg';
        row.append( item );

        const content = document.createElement( 'span' );
        content.textContent = message.content;
        item.append( content );

        this.chatHistoryElement.append( row );

        if ( !noscroll ) {
            this.chatHistoryElement.scrollTop = this.chatHistoryElement.scrollHeight;
        }
    }

    restartSession() {
        this.chatHistory = [];
        this.chatHistoryElement.innerHTML = '';
        this.displayChatGPTResponse('');
        this.contentSafetyStatus.innerHTML = '';
        this.displayRecognizedText( '' );        
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});

