// src/js/text_to_speech.js
import axios from 'axios';

export class TextToSpeech {
    constructor(apiKey, voiceID ) {
        this.apiKey = apiKey;
        this.voiceId = voiceID; // Sarah voice ID
    }

    async synthesizeSpeech(text) {
        try {
            const response = await axios.post(
                `https://api.elevenlabs.io/v1/text-to-speech/${this.voiceId}`,
                { text },
                {
                    headers: {
                        'Accept': 'audio/mpeg',
                        'xi-api-key': this.apiKey,
                        'Content-Type': 'application/json'
                    },
                    responseType: 'arraybuffer'
                }
            );
            return new Blob([response.data], { type: 'audio/mpeg' });
        } catch (error) {
            console.error('Error synthesizing speech:', error);
            throw error;
        }
    }
}
